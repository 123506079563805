<template>
    <div>
        <h5 class="info-text"> Campos com (<b>*</b>) são obrigatórios.</h5>
        <div class="md-layout">
            <div class="md-layout-item md-size-100 md-small-size-100">
                <div class="picture-container">
                    <div class="picture">
                        <div v-if="!image">
                            <img :src="avatar" class="picture-src" title="">
                        </div>
                        <div v-else>
                            <img :src="image" />
                        </div>
                        <input type="file" @change="onFileChange">
                    </div>
                    <h6 class="description">Ícone da rádio*</h6>
                </div>
            </div>
            <!-- <div class="md-layout-item md-size-50 mt-4 md-small-size-100">
                <md-field :class="[
            {'md-valid': !errors.has('cnpj') && touched.cnpj},
            {'md-form-group': true},
            {'md-error': errors.has('cnpj')}]">
                    <md-icon></md-icon>
                    <label>CNPJ</label>
                    <md-input v-model="cnpj" data-vv-name="cnpj" type="text" name="cnpj" required v-validate="modelValidations.cnpj" maxlength="14">
                    </md-input>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors.has('cnpj')">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                        <md-icon class="success" v-show="!errors.has('cnpj') && touched.cnpj">done</md-icon>
                    </slide-y-down-transition>
                </md-field>
            </div> -->

            <div class="md-layout-item md-size-50 mt-4 md-small-size-100">
                <md-field :class="[
            {'md-valid': !errors.has('comercial_name') && touched.comercial_name},
            {'md-form-group': true},
            {'md-error': errors.has('comercial_name')}]">
                    <md-icon></md-icon>
                    <label>Nome comercial</label>
                    <md-input v-model="comercial_name" data-vv-name="comercial_name" type="text" name="comercial_name" required v-validate="modelValidations.comercial_name">
                    </md-input>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors.has('comercial_name')">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                        <md-icon class="success" v-show="!errors.has('comercial_name') && touched.comercial_name">done</md-icon>
                    </slide-y-down-transition>
                </md-field>
            </div>

            <div class="md-layout-item md-size-50 mt-4 md-small-size-100">
                <md-field :class="[
            {'md-valid': !errors.has('social_name') && touched.social_name},
            {'md-form-group': true},
            {'md-error': errors.has('social_name')}]">
                    <md-icon></md-icon>
                    <label>Nome social</label>
                    <md-input v-model="social_name" data-vv-name="social_name" type="text" name="social_name" required v-validate="modelValidations.social_name">
                    </md-input>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors.has('social_name')">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                        <md-icon class="success" v-show="!errors.has('social_name') && touched.social_name">done</md-icon>
                    </slide-y-down-transition>
                </md-field>
            </div>

            <div class="md-layout-item md-size-50 mt-4 md-small-size-100">
                <md-field :class="[{'md-form-group': true}]">
                    <md-icon></md-icon>
                    <label>Nome fantasia</label>
                    <md-input v-model="fantasy_name" data-vv-name="fantasy_name" type="text" name="fantasy_name">
                    </md-input>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors.has('fantasy_name')">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                        <md-icon class="success" v-show="!errors.has('fantasy_name') && touched.fantasy_name">done</md-icon>
                    </slide-y-down-transition>
                </md-field>
            </div>

            <div class="md-layout-item md-size-50 mt-4 md-small-size-100">
                <md-field :class="[
            {'md-valid': !errors.has('phone') && touched.phone},
            {'md-form-group': true},
            {'md-error': errors.has('phone')}]">
                    <md-icon></md-icon>
                    <label>Telefone com DDD</label>
                    <md-input v-model="phone" data-vv-name="phone" type="text" name="phone" required v-validate="modelValidations.phone" maxlength="11">
                    </md-input>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors.has('phone')">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                        <md-icon class="success" v-show="!errors.has('phone') && touched.phone">done</md-icon>
                    </slide-y-down-transition>
                </md-field>
            </div>

            <div class="md-layout-item md-size-50 mt-4 md-small-size-100">
                <md-field :class="[
            {'md-valid': !errors.has('email') && touched.email},
            {'md-form-group': true},
            {'md-error': errors.has('email')}]">
                    <md-icon></md-icon>
                    <label>Email</label>
                    <md-input v-model="email" data-vv-name="email" type="text" name="email">
                    </md-input>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors.has('email')">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                        <md-icon class="success" v-show="!errors.has('email') && touched.email">done</md-icon>
                    </slide-y-down-transition>
                </md-field>
            </div>

            <div class="md-layout-item md-size-50 mt-4 md-small-size-100">
                <md-field :class="[{'md-form-group': true}]">
                    <md-icon></md-icon>
                    <label>Link de Streaming</label>
                    <md-input v-model="streaming" data-vv-name="streaming" type="text" name="streaming">
                    </md-input>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors.has('streaming')">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                        <md-icon class="success" v-show="!errors.has('streaming') && touched.streaming">done</md-icon>
                    </slide-y-down-transition>
                </md-field>
            </div>

            <div class="md-layout-item md-size-100 mt-4 md-small-size-100">
                <md-field :class="[
            {'md-valid': !errors.has('site') && touched.site},
            {'md-form-group': true},
            {'md-error': errors.has('site')}]">
                    <md-icon></md-icon>
                    <label>Site</label>
                    <md-input v-model="site" data-vv-name="site" type="text" name="site">
                    </md-input>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors.has('site')">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                        <md-icon class="success" v-show="!errors.has('site') && touched.site">done</md-icon>
                    </slide-y-down-transition>
                </md-field>
            </div>

            <div class="md-layout-item md-size-100 mt-4 md-small-size-100">
                <md-field :class="[
            {'md-valid': !errors.has('description') && touched.description},
            {'md-form-group': true},
            {'md-error': errors.has('description')}]">
                    <md-icon></md-icon>
                    <label>Descrição</label>
                    <md-textarea v-model="description" data-vv-name="description" type="text" name="description">
                    </md-textarea>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors.has('description')">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                        <md-icon class="success" v-show="!errors.has('description') && touched.description">done</md-icon>
                    </slide-y-down-transition>
                </md-field>
            </div>

            <div class="md-layout-item md-size-50 mt-4 md-small-size-100">
                <md-field :class="[
            {'md-valid': !errors.has('cep') && touched.cep},
            {'md-form-group': true},
            {'md-error': errors.has('cep')}]">
                    <md-icon></md-icon>
                    <label>CEP - Sem pontos</label>
                    <md-input v-model="cep" data-vv-name="cep" type="text" name="cep" required v-validate="modelValidations.cep" maxlength="8" @blur="searchCep()">
                    </md-input>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors.has('cep')">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                        <md-icon class="success" v-show="!errors.has('cep') && touched.cep">done</md-icon>
                    </slide-y-down-transition>
                </md-field>
            </div>

            <div class="md-layout-item md-size-50 mt-4 md-small-size-100">
                <md-field :class="[
            {'md-valid': !errors.has('state') && touched.state},
            {'md-form-group': true},
            {'md-error': errors.has('state')}]">
                    <md-icon></md-icon>
                    <label>Estado</label>
                    <md-input v-model="state" data-vv-name="state" name="state" required v-validate="modelValidations.state" :disabled="disabled">
                    </md-input>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors.has('state')">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                        <md-icon class="success" v-show="!errors.has('state') && touched.state">done</md-icon>
                    </slide-y-down-transition>
                </md-field>
            </div>

            <div class="md-layout-item md-size-50 mt-4 md-small-size-100">
                <md-field :class="[
            {'md-valid': !errors.has('city') && touched.city},
            {'md-form-group': true},
            {'md-error': errors.has('city')}]">
                    <md-icon></md-icon>
                    <label>Cidade</label>
                    <md-input v-model="city" data-vv-name="city" type="text" name="city" required v-validate="modelValidations.city" :disabled="disabled">
                    </md-input>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors.has('city')">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                        <md-icon class="success" v-show="!errors.has('city') && touched.city">done</md-icon>
                    </slide-y-down-transition>
                </md-field>
            </div>

            <div class="md-layout-item md-size-50 mt-4 md-small-size-100">
                <md-field :class="[
            {'md-valid': !errors.has('neighborhood') && touched.neighborhood},
            {'md-form-group': true},
            {'md-error': errors.has('neighborhood')}]">
                    <md-icon></md-icon>
                    <label>Bairro</label>
                    <md-input v-model="neighborhood" data-vv-name="neighborhood" type="text" name="neighborhood" required v-validate="modelValidations.neighborhood" :disabled="disabled">
                    </md-input>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors.has('neighborhood')">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                        <md-icon class="success" v-show="!errors.has('neighborhood') && touched.neighborhood">done</md-icon>
                    </slide-y-down-transition>
                </md-field>
            </div>

            <div class="md-layout-item md-size-50 mt-4 md-small-size-100">
                <md-field :class="[
            {'md-valid': !errors.has('address') && touched.address},
            {'md-form-group': true},
            {'md-error': errors.has('address')}]">
                    <md-icon></md-icon>
                    <label>Endereço</label>
                    <md-input v-model="address" data-vv-name="address" type="text" name="address" required v-validate="modelValidations.address" :disabled="disabled">
                    </md-input>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors.has('address')">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                        <md-icon class="success" v-show="!errors.has('address') && touched.address">done</md-icon>
                    </slide-y-down-transition>
                </md-field>
            </div>

            <div class="md-layout-item md-size-50 mt-4 md-small-size-100">
                <md-field :class="[
            {'md-valid': !errors.has('number') && touched.number},
            {'md-form-group': true},
            {'md-error': errors.has('number')}]">
                    <md-icon></md-icon>
                    <label>Número</label>
                    <md-input v-model="number" data-vv-name="number" type="text" name="number" required v-validate="modelValidations.number">
                    </md-input>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors.has('number')">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                        <md-icon class="success" v-show="!errors.has('number') && touched.number">done</md-icon>
                    </slide-y-down-transition>
                </md-field>
            </div>

            <div class="md-layout-item md-size-50 mt-4 md-small-size-100">
                <md-field :class="[
            {'md-valid': !errors.has('complement') && touched.complement},
            {'md-form-group': true},
            {'md-error': errors.has('complement')}]">
                    <md-icon></md-icon>
                    <label>Complemento</label>
                    <md-input v-model="complement" data-vv-name="complement" type="text" name="complement" v-validate="modelValidations.complement">
                    </md-input>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors.has('complement')">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                        <md-icon class="success" v-show="!errors.has('complement') && touched.complement">done</md-icon>
                    </slide-y-down-transition>
                </md-field>
            </div>
        </div>
        <div id="snackbar"></div>
    </div>
</template>
<script>
    import {
        SlideYDownTransition
    } from 'vue2-transitions'
    import axios from 'axios'
    import Swal from 'sweetalert2'
    import services from '../../../../routes/services'
    export default {
        components: {
            SlideYDownTransition
        },
        props: {
            avatar: {
                type: String,
                default: './img/default-avatar.png'
            }
        },
        data() {
            return {
                image: '',
                single: null,
                cnpj: '',
                comercial_name: '',
                social_name: '',
                fantasy_name: '',
                number: '',
                cep: '',
                state: '',
                city: '',
                neighborhood: '',
                complement: '',
                description: '',
                address: '',
                phone: '',
                email: '',
                logoFile: '',
                streaming: '',
                site: '',
                touched: {
                    cnpj: false,
                    comercial_name: false,
                    social_name: false,
                    number: false,
                    cep: false,
                    state: false,
                    city: false,
                    neighborhood: false,
                    complement: false,
                    address: false,
                    description: false,
                    phone: false,
                    email: false,
                    streaming: false
                },
                disabled: false,
                modelValidations: {
                    cnpj: {
                        required: true,
                        min: 14
                    },
                    comercial_name: {
                        required: true,
                        min: 3
                    },
                    social_name: {
                        required: true,
                        min: 3
                    },
                    number: {
                        required: true,
                        min: 1
                    },
                    cep: {
                        required: true,
                        min: 8
                    },
                    state: {
                        required: true
                    },
                    city: {
                        required: true,
                        min: 3
                    },
                    neighborhood: {
                        required: true,
                        min: 3
                    },
                    address: {
                        required: true,
                        min: 3
                    },
                    description: {
                        required: true,
                        min: 10
                    },
                    streaming: {
                        required: true,
                        min: 3
                    },
                    email: {
                        required: true,
                        email: true
                    },
                    phone: {
                        required: true,
                        min: 10
                    }
                }
            }
        },
        methods: {
            handlePreview(file) {
                this.model.imageUrl = URL.createObjectURL(file.raw);
            },
            getError(fieldName) {
                return this.errors.first(fieldName)
            },
            validate() {
                const radio = {
                    comercial_name: this.comercial_name,
                    social_name: this.social_name,
                    fantasy_name: this.fantasy_name,
                    cep: this.cep,
                    state: this.state,
                    city: this.city,
                    neighborhood: this.neighborhood,
                    complement: this.complement,
                    description: this.description,
                    address: this.address,
                    phone: this.phone,
                    email: this.email,
                    streaming: this.streaming,
                    site: this.site,
                    logoFile: this.image
                }
                if(this.logoFile == '') {
                    this.getSnackBar('Nenhum ícone foi adicionado, por favor, confira antes de prosseguir.')
                } else {
                    return this.$validator.validateAll().then(res => {
                        sessionStorage.setItem('radio', JSON.stringify(radio))
                        this.$emit('on-validated', res)
                        return res
                    })
                }
            },
            onFileChange(e) {
                var files = e.target.files || e.dataTransfer.files;
                if (!files.length)
                    return;
                this.createImage(files[0]);
            },
            createImage(file) {
                this.logoFile = file
                var reader = new FileReader();
                var vm = this;
                reader.onload = (e) => {
                    vm.image = e.target.result;
                };
                reader.readAsDataURL(file);
            },
            searchCep() {
                Swal.fire({
                    title: "Aguarde",
                    html: "Buscando CEP...",
                    onOpen: () => {
                        Swal.showLoading()
                        const url = `https://viacep.com.br/ws/${this.cep}/json/`
                            if (this.cep != "") {
                                axios.get(url).then((res) => {
                                        if(res.data.erro) {
                                            this.getSnackBar('Cep não encontrado, por favor, tente novamente.')
                                            return false
                                        }

                                        this.state = res.data.uf
                                        this.city = res.data.localidade
                                        this.address = res.data.logradouro
                                        this.neighborhood = res.data.bairro
                                        Swal.close()
                                    })
                                    .catch((e) => {
                                        this.getSnackBar('Cep não encontrado, por favor, tente novamente.')
                                        console.log(e)
                                    })
                            }
                    }
                })
            },
            getSnackBar(txt) {
                // Get the snackbar DIV
                let x = document.getElementById("snackbar");

                x.innerHTML = txt;

                // Add the "show" class to DIV
                x.className = "show";

                // After 3 seconds, remove the show class from DIV
                setTimeout(function() {
                    x.className = x.className.replace("show", "");
                }.bind(), 3000);
            },
            upload: function() {
                const formData = new FormData()
                const config = {
                    headers: { 'content-type': 'multipart/form-data' }
                }
                formData.append('key', this.appKey)
                formData.append('attachments', this.uploadFile)

                return services().post('services/uploadCloudImage', formData, config).then(res => {
                    return res.data
                })
            },
            createRadio() {

            }
        },
        watch: {
            cnpj() {
                this.touched.cnpj = true
            },
            comercial_name() {
                this.touched.comercial_name = true
            },
            social_name() {
                this.touched.social_name = true
            },
            number() {
                this.touched.number = true
            },
            cep() {
                if (this.cep !== "") {
                    this.disabled = true
                } else {
                    this.disabled = false
                }

                this.touched.cep = true
            },
            state() {
                this.touched.state = true
            },
            city() {
                this.touched.city = true
            },
            neighborhood() {
                this.touched.neighborhood = true
            },
            address() {
                this.touched.address = true
            },
            description() {
                this.touched.description = true
            },
            phone() {
                this.touched.phone = true
            },
            streaming() {
                this.touched.streaming = true
            },
            email() {
                this.touched.email = true
            }
        },
        mounted() {
            if(!sessionStorage.getItem('plans')) {
                this.$router.push('/pricing')
            }
        }
    }
</script>
<style>
    .md-menu.md-select {
        margin-left: 10px;
    }

    i.md-icon.md-icon-font.md-icon-image.md-theme-default {
        display: none;
    }

    .md-field.md-has-textarea:not(.md-autogrow) label {
        left: 35px !important;
    }

    .md-field.md-has-textarea:not(.md-autogrow).md-focused .md-textarea,
    .md-field.md-has-textarea:not(.md-autogrow).md-has-value .md-textarea {
        padding-top: 30px !important;
    }

    textarea {
        margin-left: 20px !important;
    }

    /* The snackbar - position it at the bottom and in the middle of the screen */
    #snackbar {
        visibility: hidden;
        /* Hidden by default. Visible on click */
        min-width: 250px;
        /* Set a default minimum width */
        margin-left: -125px;
        /* Divide value of min-width by 2 */
        background-color: #333;
        /* Black background color */
        color: #fff;
        /* White text color */
        text-align: center;
        /* Centered text */
        border-radius: 2px;
        /* Rounded borders */
        padding: 16px;
        /* Padding */
        position: fixed;
        /* Sit on top of the screen */
        z-index: 1;
        /* Add a z-index if needed */
        left: 50%;
        /* Center the snackbar */
        bottom: 30px;
        /* 30px from the bottom */
    }

    /* Show the snackbar when clicking on a button (class added with JavaScript) */
    #snackbar.show {
        visibility: visible;
        /* Show the snackbar */
        /* Add animation: Take 0.5 seconds to fade in and out the snackbar. 
    However, delay the fade out process for 2.5 seconds */
        -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
        animation: fadein 0.5s, fadeout 0.5s 2.5s;
    }

    /* Animations to fade the snackbar in and out */
    @-webkit-keyframes fadein {
        from {
            bottom: 0;
            opacity: 0;
        }

        to {
            bottom: 30px;
            opacity: 1;
        }
    }

    @keyframes fadein {
        from {
            bottom: 0;
            opacity: 0;
        }

        to {
            bottom: 30px;
            opacity: 1;
        }
    }

    @-webkit-keyframes fadeout {
        from {
            bottom: 30px;
            opacity: 1;
        }

        to {
            bottom: 0;
            opacity: 0;
        }
    }

    @keyframes fadeout {
        from {
            bottom: 30px;
            opacity: 1;
        }

        to {
            bottom: 0;
            opacity: 0;
        }
    }
</style>