<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <h5 class="info-text"> Insira as informações de pagamento abaixo </h5>
    </div>
    <div class="md-layout-item md-size-50 md-small-size-100">
      <md-field :class="[
        {'md-valid': !errors.has('card_number') && touched.card_number},
        {'md-error': errors.has('card_number')}]">
        <label>Número do cartão</label>
        <md-input
          v-model="card_number"
          data-vv-name="card_number"
          type="text"
          name="card_number"
          required
          v-validate="modelValidations.card_number"
          maxlength="16">
        </md-input>
        <slide-y-down-transition>
          <md-icon class="error" v-show="errors.has('card_number')">close</md-icon>
        </slide-y-down-transition>
        <slide-y-down-transition>
          <md-icon class="success" v-show="!errors.has('card_number') && touched.card_number">done</md-icon>
        </slide-y-down-transition>
      </md-field>
    </div>

    <div class="md-layout-item md-size-50 md-small-size-100">
      <md-field :class="[
        {'md-valid': !errors.has('card_holder_name') && touched.card_holder_name},
        {'md-error': errors.has('card_holder_name')}]">
        <label>Nome (como escrito no cartão)</label>
        <md-input
          v-model="card_holder_name"
          data-vv-name="card_holder_name"
          type="text"
          name="card_holder_name"
          required
          v-validate="modelValidations.card_holder_name"
          style="text-transform:uppercase">
        </md-input>
        <slide-y-down-transition>
          <md-icon class="error" v-show="errors.has('card_holder_name')">close</md-icon>
        </slide-y-down-transition>
        <slide-y-down-transition>
          <md-icon class="success" v-show="!errors.has('card_holder_name') && touched.card_holder_name">done</md-icon>
        </slide-y-down-transition>
      </md-field>
    </div>

    <div class="md-layout-item md-size-50 md-small-size-100">
      <md-field :class="[
        {'md-valid': !errors.has('card_expiration_month') && touched.card_expiration_month},
        {'md-error': errors.has('card_expiration_month')}]">
        <label>Mês de expiração</label>
        <md-input
          v-model="card_expiration_month"
          data-vv-name="card_expiration_month"
          type="text"
          name="card_expiration_month"
          required
          v-validate="modelValidations.card_expiration_month"
          maxlength="2">
        </md-input>
        <slide-y-down-transition>
          <md-icon class="error" v-show="errors.has('card_expiration_month')">close</md-icon>
        </slide-y-down-transition>
        <slide-y-down-transition>
          <md-icon class="success" v-show="!errors.has('card_expiration_month') && touched.card_expiration_month">done</md-icon>
        </slide-y-down-transition>
      </md-field>
    </div>

    <div class="md-layout-item md-size-50 md-small-size-100">
      <md-field :class="[
        {'md-valid': !errors.has('card_expiration_year') && touched.card_expiration_year},
        {'md-error': errors.has('card_expiration_year')}]">
        <label>Ano de expiração</label>
        <md-input
          v-model="card_expiration_year"
          data-vv-name="card_expiration_year"
          type="text"
          name="card_expiration_year"
          required
          v-validate="modelValidations.card_expiration_year"
          maxlength="4">
        </md-input>
        <slide-y-down-transition>
          <md-icon class="error" v-show="errors.has('card_expiration_year')">close</md-icon>
        </slide-y-down-transition>
        <slide-y-down-transition>
          <md-icon class="success" v-show="!errors.has('card_expiration_year') && touched.card_expiration_year">done</md-icon>
        </slide-y-down-transition>
      </md-field>
    </div>

    <div class="md-layout-item md-size-50 md-small-size-100">
      <md-field :class="[
        {'md-valid': !errors.has('card_cvv') && touched.card_cvv},
        {'md-error': errors.has('card_cvv')}]">
        <label>Código de segurança</label>
        <md-input
          v-model="card_cvv"
          data-vv-name="card_cvv"
          type="text"
          name="card_cvv"
          required
          v-validate="modelValidations.card_cvv"
          maxlength="3">
        </md-input>
        <slide-y-down-transition>
          <md-icon class="error" v-show="errors.has('card_cvv')">close</md-icon>
        </slide-y-down-transition>
        <slide-y-down-transition>
          <md-icon class="success" v-show="!errors.has('card_cvv') && touched.card_cvv">done</md-icon>
        </slide-y-down-transition>
      </md-field>
    </div>

    <div class="md-layout-item md-size-50 md-small-size-100">
      <md-field :class="[
        {'md-valid': !errors.has('card_cpf_cnpj') && touched.card_cpf_cnpj},
        {'md-error': errors.has('card_cpf_cnpj')}]">
        <label>CPF ou CNPJ sem separadores</label>
        <md-input
          v-model="card_cpf_cnpj"
          data-vv-name="card_cpf_cnpj"
          type="text"
          name="card_cpf_cnpj"
          required
          v-validate="modelValidations.card_cpf_cnpj"
          maxlength="14">
        </md-input>
        <slide-y-down-transition>
          <md-icon class="error" v-show="errors.has('card_cpf_cnpj')">close</md-icon>
        </slide-y-down-transition>
        <slide-y-down-transition>
          <md-icon class="success" v-show="!errors.has('card_cpf_cnpj') && touched.card_cpf_cnpj">done</md-icon>
        </slide-y-down-transition>
      </md-field>
    </div>

    <div id="snackbar2"></div>
  </div>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
import cardData from '../../../../utils/events/finishCadCard.js'
import services from '../../../../routes/services.js'
import models from '../../../../routes/models.js'
import axios from 'axios'
import Swal from 'sweetalert2';
const md5 = require('md5');
  export default {
    components: { SlideYDownTransition },
    data() {
      return {
        card_number: '',
        card_holder_name: '',
        card_expiration_month: '',
        card_expiration_year: '',
        card_cvv: '',
        card_cpf_cnpj: '',
        cloudURL: '',

        touched: {
          card_number: false,
          card_holder_name: false,
          card_expiration_month: false,
          card_nucard_expiration_yearmber: false,
          card_cvv: false,
          card_cpf_cnpj: false
        },

        modelValidations: {
          card_number: {
            required: true,
            min: 16
          },
          card_holder_name: {
            required: true,
            min: 5
          },
          card_expiration_month: {
            required: true,
            min: 2
          },
          card_expiration_year: {
            required: true,
            min: 4
          },
          card_cvv: {
            required: true,
            min: 3
          },
          card_cpf_cnpj: {
            required: true,
            min: 11
          }
        }
      }
    },
    methods: {
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      validate() {
        return this.$validator.validateAll().then(res => {
          this.$emit('on-validated', res)
          return res
        })
      },
      getDataCard() {
        let card = {}
        card.card_holder_name = this.card_holder_name
        card.card_expiration_date = this.card_expiration_month + '/' + this.card_expiration_year
        card.card_number = this.card_number
        card.card_cvv = this.card_cvv

        const cardValidations = pagarme.validate({card: card})

        if(!cardValidations.card.card_number) {
          let txt = "Número do cartão inválido."
          this.getSnackBar(txt)
        }
        if(!cardValidations.card.card_holder_name) {
          let txt = "Nome do portador inválido."
          this.getSnackBar(txt)
        }
        if(!cardValidations.card.card_expiration_month) {
          let txt = "Mês de expiração inválido."
          this.getSnackBar(txt)
        }
        if(!cardValidations.card.card_expiration_year) {
          let txt = "Ano de expiração inválido."
          this.getSnackBar(txt)
        }
        if(!cardValidations.card.card_cvv) {
          let txt = "Código de segurança inválido."
          this.getSnackBar(txt)
        }

        const planId = JSON.parse(sessionStorage.getItem('plans')).id
        const str = this.card_expiration_year.substring(2, 4)
        const email = JSON.parse(sessionStorage.getItem('user')).email
        const customerName = JSON.parse(sessionStorage.getItem('user')).firstname + ' ' + JSON.parse(sessionStorage.getItem('user')).lastname
        const formData = {
          api_key: 'ak_test_o6pNlMnzryy2DnrpqaIkG4iqy6ex5S',
          plan_id: planId,
            card_number: card.card_number,
            card_holder_name: card.card_holder_name.toUpperCase(),
            card_expiration_date: this.card_expiration_month + str,
            card_cvv: card.card_cvv,
            customer: {
              name: customerName,
              email: email,
              document_number: this.card_cpf_cnpj
            }
        }
        axios.post("https://api.pagar.me/1/subscriptions", formData).then((resp) => {
          models().put("users/updateUserPagarMe", {"email": email, "pagarmeId": resp.data.id}).then(() => {
            Swal.fire({
              title: "Pronto!",
              html: `Seu cadastro e assinatura foram gerados com sucesso! Em breve você receberá uma confirmação no email registrado: <b>${JSON.parse(sessionStorage.getItem('user')).email}</b>`,
              type: "success"
            })
            sessionStorage.clear()
            this.$router.push('/login')
          })
          .catch((e) => {
            Swal(
              'Erro',
              'Não foi possível processar as informações de pagamento nesse instante, por favor, entre em contato com nosso suporte.',
              'error'
            )
            console.log(e)
          })
        })
      },

      registerRadioUser() {
        const radio = JSON.parse(sessionStorage.getItem('radio'))
        const user = JSON.parse(sessionStorage.getItem('user'))
        const key = md5(radio.comercial_name + ' ' + user.email)
        const created = this.createdDate()
        const plan = JSON.parse(sessionStorage.getItem('plans')).name
        let planType = null
        if(plan == "Premium") {planType = 228}

        const formData = {
          name: radio.comercial_name,
          radio_type_id: 2,
          key: key,
          image_logo_url: 'default',
          description: radio.description,
          phone_number: radio.phone,
          email: radio.email,
          site: radio.site,
          state: radio.state,
          city: radio.city,
          profile_id: planType,
          created: created,
          firstname: user.firstname,
          lastname: user.lastname,
          email: user.email,
          password: user.password,
          mobile: user.mobile
        }

        Swal.fire({
          title: "Processando informações",
          html: "Aguarde enquanto processamos as informações de cadastro e pagamento...",
          type: "warning",
          onOpen: () => {
            Swal.showLoading()
            services().post('radios/createRadio', formData)
              .then((resp) => {
                if (resp.data.id) {
                  Promise.resolve(this.upload(resp.data.key, radio.logoFile))
                  .then((cloudUrl) => {
                    this.updateLogoUrl(cloudUrl, resp.data.key)
                  })
                  .then(() => {
                    this.getDataCard()
                  })
                  .catch((e) => {
                    Swal('Falha', 'Não foi possível cadastrar a imagem da rádio, por favor, entre em contato com nosso suporte.', 'error')
                    console.log(e)
                  })
                }
              })
              .catch((e) => {
                Swal(
                    'Falha',
                    'Tivemos problemas ao processar as informações, por favor, tente novamente.',
                    'error'
                  )
                console.log(e)
              })
          }
        })
      },

      // createRadio() {
      //   const radio = JSON.parse(sessionStorage.getItem('radio'))
      //   const user = JSON.parse(sessionStorage.getItem('user'))
      //   const key = md5(radio.comercial_name + ' ' + user.email)
      //   const created = this.createdDate()
      //   const plan = JSON.parse(sessionStorage.getItem('plans')).name
      //   let planType = null
      //   if(plan == "Premium") {planType = 228}

      //   const formDataRadio = {
      //     name: radio.comercial_name,
      //     radio_type_id: 2,
      //     key: key,
      //     image_logo_url: 'default',
      //     description: radio.description,
      //     phone_number: radio.phone,
      //     email: radio.email,
      //     site: radio.site,
      //     state: radio.state,
      //     city: radio.city,
      //     profile_id: planType,
      //     created: created,
      //     firstname: firstname,
      //     lastname: lastname,
      //     email: email,
      //     password: password,
      //     mobile: mobile
      //   }

      //   Swal.fire({
      //     title: "Processando informações",
      //     html: "Aguarde enquanto processamos as informações de cadastro e pagamento...",
      //     type: "warning",
      //     onOpen: () => {
      //       Swal.showLoading()
      //       services().post('radios/createRadio', formDataRadio)
      //         .then((resp) => {
      //           if (resp.data.id) {
      //             Promise.resolve(this.upload(resp.data.key, radio.logoFile))
      //             .then((cloudUrl) => {
      //               this.updateLogoUrl(cloudUrl, resp.data.key)
      //             })
      //             .catch((e) => {
      //               Swal('Falha', 'Não foi possível cadastrar a imagem da rádio, por favor, entre em contato com nosso suporte.', 'error')
      //               console.log(e)
      //             })
      //           }
      //         })
      //         .catch((e) => {
      //           Swal(
      //               'Falha',
      //               'Tivemos problemas ao processar as informações, por favor, tente novamente.',
      //               'error'
      //             )
      //           console.log(e)
      //         })
      //     }
      //   })
      // },
      // createUser(radioId) {
      //   const user = JSON.parse(sessionStorage.getItem('user'))
      //   services().post('radios/createUser', user)
      //   .then((resp) => {
      //     this.addUserToRadio(radioId, resp.data.id)
      //   })
      //   .catch((e) => {
      //     console.log(e)
      //   })
      // },
      // addUserToRadio(radioId, userId) {
      //   const form = {
      //     radioId: radioId,
      //     userId: userId
      //   }
      //   services().post('radios/addUserToRadio', form)
      //   .then((resp) => {
      //     this.getDataCard()
      //   })
      //   .catch((e) => {
      //     console.log(e)
      //   })
      // },
      getSnackBar(txt) {
        let x = document.getElementById("snackbar2");
        x.innerHTML = txt;
        x.className = "show";

        setTimeout(function() {
            x.className = x.className.replace("show", "");
        }.bind(), 3000);
      },
      upload: function(key, file) {
        let blob = this.dataURItoBlob(file);
        const urlCloud = 'https://api.painel.mobradio.com.br/services/uploadCloudImage'
        const formData = new FormData()
        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }
        formData.append('key', key)
        formData.append('attachments', blob)

        return services().post('services/uploadCloudImage', formData, config).then(res => {
          return res.data.data.url
        })
      },
      dataURItoBlob(dataURI) {
    
          var byteString;
          if (dataURI.split(',')[0].indexOf('base64') >= 0)
              byteString = atob(dataURI.split(',')[1]);
          else
              byteString = unescape(dataURI.split(',')[1]);

          // separate out the mime component
          var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

          // write the bytes of the string to a typed array
          var ia = new Uint8Array(byteString.length);
          for (var i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
          }

          return new Blob([ia], {type:mimeString});
      },
      updateLogoUrl(imageLogoUrl, key) {
        const form = {
          imageLogoUrl: imageLogoUrl,
          key: key
        }
        services().put('radios/updateRadioLogoUrl', form)
          .then((resp) => {
            console.log("Link do Cloudinary adicionado no campo: 'image_logo_url'")
          })
          .catch((e) => {
            console.log("Link do Cloudinary: " + e)
          })
      },
      createdDate() {
        const date = new Date()
        let day = date.getDate()
        let month = date.getMonth()
        let year = date.getFullYear()
        let hour = date.getHours()
        let minutes = date.getMinutes()
        let seconds = date.getSeconds()

        if(day < 10) {day = '0' + day}
        if(month < 10) {month = '0' + (month+1)}


        if(hour < 10) {hour = '0' + hour}
        if(minutes < 10) {minutes = '0' + minutes}
        if(seconds < 10) {seconds = '0' + seconds}

        const formatDate = year + '/' + month + '/' + day + ' ' + hour + ':' + minutes + ':' + seconds
        return formatDate;
      }
    },
    watch: {
      street () {
        this.touched.street = true
      },
      streetNo () {
        this.touched.streetNo = true
      }
    },
    mounted() {
      cardData.$on('FinishDataCard', (payload) => {
        this.registerRadioUser()
      })
    }
  }
</script>
<style>
#snackbar2 {
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 250px; /* Set a default minimum width */
  margin-left: -125px; /* Divide value of min-width by 2 */
  background-color: #333; /* Black background color */
  color: #fff; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 1; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  bottom: 30px; /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar2.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar. 
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;} 
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;} 
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}
</style>
