<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-66 md-xsmall-size-80 mx-auto">
      <simple-wizard>
        <template slot="header">
          <h3 class="title">Casdastro do Plano</h3>
          <!-- <h5 class="category">Insira as informações da rádio abaixo.</h5> -->
        </template>

        <wizard-tab :before-change="() => validateStep('step1')">
        <!-- <wizard-tab> -->
          <template slot="label">
            Rádio
          </template>
          <first-step ref="step1" @on-validated="onStepValidated"></first-step>
        </wizard-tab>

        <wizard-tab :before-change="() => validateStep('step2')">
        <!-- <wizard-tab> -->
          <template slot="label" >
            Usuário
          </template>
          <second-step ref="step2" @on-validated="onStepValidated"></second-step>
        </wizard-tab>

        <wizard-tab :before-change="() => validateStep('step3')">
          <template slot="label">
            Dados do pagamento
          </template>
          <third-step ref="step3"></third-step>
        </wizard-tab>
      </simple-wizard>
    </div>
  </div>
</template>
<script>
  import FirstStep from './Wizard/FirstStep.vue'
  import SecondStep from './Wizard/SecondStep.vue'
  import ThirdStep from './Wizard/ThirdStep.vue'
  import Swal from 'sweetalert2'
  import {SimpleWizard, WizardTab} from '@/components'

  export default {
    data() {
      return {
        wizardModel: {}
      }
    },
    components: {
      FirstStep,
      SecondStep,
      ThirdStep,
      SimpleWizard,
      WizardTab
    },
    methods: {
      validateStep(ref) {
        return this.$refs[ref].validate()
      },
      onStepValidated(validated, model) {
        this.wizardModel = {...this.wizardModel, ...model}
      },
      wizardComplete() {
        Swal('Good job!', 'You clicked the finish button!', 'success')
      }
    }
  }
</script>
