<template>
  <div>
    <h5 class="info-text"> Campos com (<b>*</b>) são obrigatórios </h5>
    <div class="md-layout">
      <div class="md-layout-item md-size-50 mt-4 md-small-size-100">
        <md-field :class="[
          {'md-valid': !errors.has('firstname') && touched.firstname},
          {'md-form-group': true},
          {'md-error': errors.has('firstname')}]">
          <md-icon></md-icon>
          <label>Nome</label>
          <md-input
            v-model="firstname"
            data-vv-name="firstname"
            type="text"
            name="firstname"
            required
            v-validate="modelValidations.firstname">
          </md-input>
          <slide-y-down-transition>
            <md-icon class="error" v-show="errors.has('firstname')">close</md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon class="success" v-show="!errors.has('firstname') && touched.firstname">done</md-icon>
          </slide-y-down-transition>
        </md-field>
      </div>

      <div class="md-layout-item md-size-50 mt-4 md-small-size-100">
        <md-field :class="[
          {'md-valid': !errors.has('lastname') && touched.lastname},
          {'md-form-group': true},
          {'md-error': errors.has('lastname')}]">
          <md-icon></md-icon>
          <label>Sobrenome</label>
          <md-input
            v-model="lastname"
            data-vv-name="lastname"
            type="text"
            name="lastname"
            required
            v-validate="modelValidations.lastname">
          </md-input>
          <slide-y-down-transition>
            <md-icon class="error" v-show="errors.has('lastname')">close</md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon class="success" v-show="!errors.has('lastname') && touched.lastname">done</md-icon>
          </slide-y-down-transition>
        </md-field>
      </div>

      <div class="md-layout-item md-size-50 mt-4 md-small-size-100">
        <md-field :class="[
          {'md-valid': !errors.has('mobile') && touched.mobile},
          {'md-form-group': true},
          {'md-error': errors.has('mobile')}]">
          <md-icon></md-icon>
          <label>Telefone com DDD</label>
          <md-input
            v-model="mobile"
            data-vv-name="mobile"
            type="text"
            name="mobile"
            required
            v-validate="modelValidations.mobile"
            maxlength="12">
          </md-input>
          <slide-y-down-transition>
            <md-icon class="error" v-show="errors.has('mobile')">close</md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon class="success" v-show="!errors.has('mobile') && touched.mobile">done</md-icon>
          </slide-y-down-transition>
        </md-field>
      </div>

      <div class="md-layout-item md-size-50 mt-4 md-small-size-100">
        <md-field :class="[
          {'md-valid': !errors.has('email') && touched.email},
          {'md-form-group': true},
          {'md-error': errors.has('email')}]">
          <md-icon></md-icon>
          <label>Email</label>
          <md-input
            v-model="email"
            data-vv-name="email"
            type="text"
            name="email"
            required
            v-validate="modelValidations.email">
          </md-input>
          <slide-y-down-transition>
            <md-icon class="error" v-show="errors.has('email')">close</md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon class="success" v-show="!errors.has('email') && touched.email">done</md-icon>
          </slide-y-down-transition>
        </md-field>
      </div>

      <div class="md-layout-item md-size-50 mt-4 md-small-size-100">
        <md-field :class="[
          {'md-valid': !errors.has('password') && touched.password},
          {'md-form-group': true},
          {'md-error': errors.has('password')}]">
          <md-icon></md-icon>
          <label>Senha - Mínimo de 6 dígitos</label>
          <md-input
            v-model="password"
            data-vv-name="password"
            type="password"
            name="password"
            required
            v-validate="modelValidations.password">
          </md-input>
          <slide-y-down-transition>
            <md-icon class="error" v-show="errors.has('password')">close</md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon class="success" v-show="!errors.has('password') && touched.password">done</md-icon>
          </slide-y-down-transition>
        </md-field>
      </div>

      <div class="md-layout-item md-size-50 mt-4 md-small-size-100">
        <md-field :class="[
          {'md-valid': !errors.has('confirm_password') && touched.confirm_password},
          {'md-form-group': true},
          {'md-error': errors.has('confirm_password')}]">
          <md-icon></md-icon>
          <label>Confirmação de Senha</label>
          <md-input
            v-model="confirm_password"
            data-vv-name="confirm_password"
            type="password"
            name="confirm_password"
            required
            v-validate="modelValidations.confirm_password">
          </md-input>
          <slide-y-down-transition>
            <md-icon class="error" v-show="errors.has('confirm_password')">close</md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon class="success" v-show="!errors.has('confirm_password') && touched.confirm_password">done</md-icon>
          </slide-y-down-transition>
        </md-field>
      </div>
    </div>

    <div id="snackbar3"></div>
  </div>
</template>
<script>
  import {IconCheckbox} from '@/components'
  import { SlideYDownTransition } from 'vue2-transitions'
import Swal from 'sweetalert2';
import Axios from 'axios';
import services from '../../../../routes/services';
const md5 = require('md5');

  export default {
    components: {
      IconCheckbox,
      SlideYDownTransition
    },
    data() {
      return {
        model: {
          design: false,
          code: true,
          develop: false
        },
        firstname: '',
        lastname: '',
        email: '',
        mobile: '',
        password: '',
        confirm_password: '',
        touched: {
          firstname: false,
          lastname: false,
          email: false,
          mobile: false,
          password: false,
          confirm_password: false
        },

        modelValidations: {
          firstname: {
            required: true,
            min: 3
          },
          lastname: {
            required: true,
            min: 3
          },
          email: {
            required: true,
            email: true
          },
          mobile: {
            required: true,
            min: 10
          },
          password: {
            required: true,
            min: 6
          },
          confirm_password: {
            required: true,
            min: 6
          }
        }
      }
    },
    methods: {
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      validate() {
        if (this.password !== this.confirm_password) {
          this.getSnackBar('As senhas não conferem, por favor, tente novamente.')
          return false
        }

        return this.$validator.validateAll().then(res => {
          sessionStorage.setItem('user', JSON.stringify({
            "firstname": this.firstname,
            "lastname": this.lastname,
            "email": this.email,
            "mobile": this.mobile,
            "password": this.confirm_password
          }))
          const typePlan = JSON.parse(sessionStorage.getItem('plans')).name
          if (typePlan == "Premium") {
            this.$emit('on-validated', res)
            return res
          } else {
            this.createRadio()
          }
        })
      },
      getSnackBar(txt) {
        let x = document.getElementById("snackbar3");
        x.innerHTML = txt;
        x.className = "show";

        setTimeout(function() {
            x.className = x.className.replace("show", "");
        }.bind(), 3000);
      },
      upload: function(key, file) {
        let blob = this.dataURItoBlob(file);
        const urlCloud = 'https://api.painel.mobradio.com.br/services/uploadCloudImage'
        const formData = new FormData()
        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }
        formData.append('key', key)
        formData.append('attachments', blob)

        return services().post('services/uploadCloudImage', formData, config).then(res => {
          return res.data.data.url
        })
      },
      dataURItoBlob(dataURI) {
    
          var byteString;
          if (dataURI.split(',')[0].indexOf('base64') >= 0)
              byteString = atob(dataURI.split(',')[1]);
          else
              byteString = unescape(dataURI.split(',')[1]);

          // separate out the mime component
          var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

          // write the bytes of the string to a typed array
          var ia = new Uint8Array(byteString.length);
          for (var i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
          }

          return new Blob([ia], {type:mimeString});
      },
      updateLogoUrl(imageLogoUrl, key) {
        const form = {
          imageLogoUrl: imageLogoUrl,
          key: key
        }
        services().put('radios/updateRadioLogoUrl', form)
          .then((resp) => {
            console.log("Link do Cloudinary adicionado no campo: 'image_logo_url'")
          })
          .catch((e) => {
            console.log("Link do Cloudinary: " + e)
          })
      },
      createdDate() {
        const date = new Date()
        let day = date.getDate()
        let month = date.getMonth()
        let year = date.getFullYear()
        let hour = date.getHours()
        let minutes = date.getMinutes()
        let seconds = date.getSeconds()

        if(day < 10) {day = '0' + day}
        if(month < 10) {month = '0' + (month+1)}


        if(hour < 10) {hour = '0' + hour}
        if(minutes < 10) {minutes = '0' + minutes}
        if(seconds < 10) {seconds = '0' + seconds}

        const formatDate = year + '/' + month + '/' + day + ' ' + hour + ':' + minutes + ':' + seconds
        return formatDate;
      },
      addUserToRadio(radioId, userId) {
        const form = {
          radioId: radioId,
          userId: userId
        }
        services().post('radios/addUserToRadio', form)
        .then((resp) => {
          Swal.fire({
              title: "Pronto!",
              html: `Seu cadastro foi gerado com sucesso! Faça login para continuar`,
              type: "success"
            })
            sessionStorage.clear()
            this.$router.push('/login')
        })
        .catch((e) => {
          console.log(e)
        })
      },
      createRadio() {
        const radio = JSON.parse(sessionStorage.getItem('radio'))
        const key = md5(radio.comercial_name + ' ' + this.email)
        const created = this.createdDate()

        const formDataRadio = {
          name: radio.comercial_name,
          radio_type_id: 2,
          key: key,
          image_logo_url: 'default',
          description: radio.description,
          phone_number: radio.phone,
          email: radio.email,
          site: radio.site,
          state: radio.state,
          city: radio.city,
          profile_id: 227,
          created: created
        }

        Swal.fire({
          title: "Processando informações",
          html: "Aguarde enquanto processamos as informações de cadastro...",
          type: "warning",
          onOpen: () => {
            Swal.showLoading()
            services().post('radios/createRadio', formDataRadio)
              .then((resp) => {
                if (resp.data.id) {
                  Promise.resolve(this.upload(resp.data.key, radio.logoFile))
                  .then((cloudUrl) => {
                    this.updateLogoUrl(cloudUrl, resp.data.key)
                    this.createUser(resp.data.id)
                  })
                  .catch((e) => {
                    Swal('Falha', 'Não foi possível cadastrar a imagem da rádio, por favor, entre em contato com nosso suporte.', 'error')
                    console.log(e)
                  })
                }
              })
              .catch((e) => {
                Swal(
                    'Falha',
                    'Tivemos problemas ao processar as informações, por favor, tente novamente.',
                    'error'
                  )
                console.log(e)
              })
          }
        })
      },
      createUser(radioId) {
        const user = {
          "firstname": this.firstname,
          "lastname": this.lastname,
          "email": this.email,
          "mobile": this.mobile,
          "password": this.confirm_password
        }
        services().post('radios/createUser', user)
        .then((resp) => {
          this.addUserToRadio(radioId, resp.data.id)
        })
        .catch((e) => {
          console.log(e)
        })
      },
    },
    watch: {
      firstname () {
        this.touched.firstname = true
      },
      lastname () {
        this.touched.lastname = true
      },
      email () {
        this.touched.email = true
      },
      mobile () {
        this.touched.mobile = true
      },
      password () {
        this.touched.password = true
      },
      confirm_password () {
        this.touched.confirm_password = true
      }
    },
    mounted() {
      if(!sessionStorage.getItem('plans')) {
        this.$router.push('/pricing')
      }
    }
  }
</script>
<style>
#snackbar3 {
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 250px; /* Set a default minimum width */
  margin-left: -125px; /* Divide value of min-width by 2 */
  background-color: #333; /* Black background color */
  color: #fff; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 1; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  bottom: 30px; /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar3.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar. 
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;} 
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;} 
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}
</style>
