var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "md-layout" }, [
    _c(
      "div",
      { staticClass: "md-layout-item md-size-66 md-xsmall-size-80 mx-auto" },
      [
        _c(
          "simple-wizard",
          [
            _c("template", { slot: "header" }, [
              _c("h3", { staticClass: "title" }, [
                _vm._v("Casdastro do Plano"),
              ]),
            ]),
            _c(
              "wizard-tab",
              { attrs: { "before-change": () => _vm.validateStep("step1") } },
              [
                _c("template", { slot: "label" }, [_vm._v(" Rádio ")]),
                _c("first-step", {
                  ref: "step1",
                  on: { "on-validated": _vm.onStepValidated },
                }),
              ],
              2
            ),
            _c(
              "wizard-tab",
              { attrs: { "before-change": () => _vm.validateStep("step2") } },
              [
                _c("template", { slot: "label" }, [_vm._v(" Usuário ")]),
                _c("second-step", {
                  ref: "step2",
                  on: { "on-validated": _vm.onStepValidated },
                }),
              ],
              2
            ),
            _c(
              "wizard-tab",
              { attrs: { "before-change": () => _vm.validateStep("step3") } },
              [
                _c("template", { slot: "label" }, [
                  _vm._v(" Dados do pagamento "),
                ]),
                _c("third-step", { ref: "step3" }),
              ],
              2
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }